.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    min-height: 100vh;
    background-color: #0000003d;
    backdrop-filter: blur(5px);

    @media screen and (max-width: 768px) {
        padding: 0 20px;
    }

    .content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 420px;
        height: fit-content;
        padding: 50px 40px;
        border: 1px solid var(--primary-blue);
        border-radius: 20px;
        background: var(--basic-white);

        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 40px 24px;
        }

        @media screen and (max-width: 360px) {
            width: 100%;
            padding: 30px 18px;
        }

        .logo {
            position: absolute;
            top: -22.5px;
            box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);

            @media screen and (max-width: 360px) {
                width: auto;
                height: 36px;
                top: -16px;
            }
        }

        .close {
            position: absolute;
            top: -14px;
            right: -14px;
            cursor: pointer;
        }
    }
}