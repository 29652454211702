.actions {
  position: relative;
  display: grid;
  padding: 50px 0 0;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 10px;

  @media screen and (max-width: 768px) {
    padding: 30px 0 0;
    grid-template-columns: repeat(auto-fit, minmax(32px, 1fr));
    column-gap: 16px;
    row-gap: 12px;
  }

  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    z-index: 1;

    svg {
      width: auto;
      height: 100%;
    }
  }

  .clouds {
    top: 10%;
    left: 10%;
    position: absolute;
    animation-name: animateClouds;
    animation-delay: 0.5s;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    z-index: 0;
  }
}

@keyframes animateClouds {
  0% {
    top: 5%;
    left: 5%;
  }

  16% {
    top: 80%;
    left: 45%;
  }

  32% {
    top: 5%;
    left: 80%;
  }

  48% {
    top: 80%;
    left: 5%;
  }

  64% {
    top: 5%;
    left: 45%;
  }

  80% {
    top: 80%;
    left: 80%;
  }

  100% {
    top: 5%;
    left: 5%;
  }
}
