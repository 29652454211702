.product-type-image {
    width: 92px;
    height: 73px;
}

.token-item {
    position: relative; 
}

.token-item:hover > .token-delete {
    display: block;
}

.token-delete {
    position: absolute;
    display: none;
    bottom: -10px;
    cursor: pointer;
}