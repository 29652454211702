.ticker {
  overflow-y: hidden;
  height: 15px;

  .item {
    text-transform: uppercase;
    animation: appearAnimation 5s ease 0s 1 normal forwards;
  }
}

@keyframes appearAnimation {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: translateY(-15px);
  }

  15% {
    animation-timing-function: ease-in;
    opacity: 1;
    transform: translateY(0);
  }

  85% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    animation-timing-function: ease-out;
    opacity: 0;
    transform: translateY(15px);
  }
}
