.offset-header-top {
    margin-top: 90px;
}
.avatar {
    width: 50px;
    height: 50px;
}
.game-save-modal-footer {
    min-height: 200px;
    border-top: 1px solid var(--black) !important;
}
.sidebar-line {
    opacity: 1 !important;
    width: 100%;
}

/* modal css */
.modal-img {
    width: 80px;
    height: 80px;
    position: absolute;
    left: 40%;
    top: -40px;
    z-index: 2;
    border-radius: 50%;
}
.close-modal-img {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    z-index: 2;
}
.ppw-modal {
    padding: 12px;
}
.ppw-modal .modal-content {
    border: 1px solid #223b95 !important;
    border-radius: 20px !important;
}

/* header css */
.header-wrapper {
    border-bottom:  1px solid #FFFFFF;
    height: 60px;
    min-height: 60px  ;
    background: #000;  
    width: 100%;
    padding: 0 50px;
    align-items: center;
    justify-content: center;
    display: flex;

    @media screen and (max-width: 750px) {
            display: grid;
            grid-template-columns: auto auto auto;
            grid-gap: 20px;
            padding: 0 16px;
      }
}

.header-title {
    color: #EFFF00;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    margin: 0px;
    text-align: center;
}
.login {
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    color: #F1FF00;
    margin: 0px;
    cursor: pointer;
}
.sidebar-avatar {
    width: 80px;
}