.input {
  width: 168px;
  min-height: 45px;
  padding: 0 15px;
  flex-shrink: 0;
  background: var(--primary-white-transparent);
  border: none;

  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: var(--basic-white);
  outline: none;

  &::placeholder {
    color: var(--primary-white-transparent);
  }
}
