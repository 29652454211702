body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}
:root {
  --blue: #0820d6;
  --green: #1fdb76;
  --yellow: #ffe500;
  --orange: #ff6600;
  --red: #ff0000;
  --black: #000000;
  --white: #f3f3f3;
  --darkGrey: #bbb5bd;
  --darkYellow: #f7b500;
  --lightGrey: #f6f6f6;

  --basic-white: #fff;
  --primary-red: #f00;
  --primary-yellow: #faff00;
  --primary-blue: #0500ff;
  --primary-blue-100: #bbbeff;
  --primary-blue-500: #6c72ff;
  --primary-white-transparent: rgba(255, 255, 255, 0.4);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.word-break {
  word-break: break-all;
}
.font-12 {
  font-size: 12px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-18-bold {
  font-size: 18px;
  font-weight: 700;
}
.font-16-bold {
  font-size: 16px;
  font-weight: 600;
}
.font-24-bold {
  font-size: 24px;
  font-weight: 700;
}
.min-height {
  min-height: 100vh;
}
.fixed-width-70 {
  width: 70px;
}
.fixed-width-100 {
  width: 100px;
}
.fixed-width-200 {
  width: 200px;
}
.border-bottom-black {
  border-bottom: 1px solid;
}
.border-right-black {
  border-right: 1px solid;
}
.border-left-black {
  border-left: 1px solid;
}
.border-bottom-black {
  border-bottom: 1px solid;
}
.transform-button {
  transform: rotate(90deg);
}
.no-side-paddings {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.no-style-button,
.no-style-button:active,
.no-style-button:focus,
.no-style-button:hover {
  background: none !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.transform-rotate svg {
  transform: rotate(180deg);
}
.no-transform-rotate svg {
  transform: none;
}
.orange-button,
.orange-button:focus,
.orange-button:hover,
.orange-button:active {
  background: var(--orange);
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.ppw-card {
  border: 1px solid !important;
  border-radius: 10px !important;
}
.orange-button:disabled {
  background: var(--orange);
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  opacity: 0.5;
}
.cursor-pointer {
  cursor: pointer;
}
.width-15 {
  width: 15% !important;
}
.yellow-wrapper {
  background: var(--yellow);
  border-radius: 3px;
}
.yellow-text {
  color: var(--yellow);
}
.bouncer-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  opacity: 1 !important;
  z-index: 1000;
}
.bouncer-loading-small {
  transform: translate(-50%, -50%);
  color: white;
  opacity: 1 !important;
  z-index: 1000;
}
.messages_box {
  padding: 10px 20px;
}
.message {
  background: #e8e7e7;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 13px;
}
.respons {
  background: #f3f3f3;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 12px;
}

.respond{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  background-color: #2f2c2c;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
  height: 24px;
  color: white;
}
.respond-loading{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  background-color: #2f2c2c;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
  height: 24px;
  color: white;
  opacity: 0.5;
}

.opacity-5 {
  opacity: 0.5;
}
.orange-text {
  color: var(--orange);
}
.color-black {
  color: var(--black);
}

body > iframe {
  pointer-events: none;
}
.UppyModalOpenerBtn {
  cursor: pointer;
}
