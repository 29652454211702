.container {
  width: 100%;

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    row-gap: 16px;
  
    .field {
      position: relative;
      width: 100%;
      border: 1px solid var(--black);
      color: var(--black);

      &::placeholder {
        color: var(--black);
        opacity: 0.35;
      }
    }

    .submit {
      width: 100%;
    }
  }
}