.layout {
  min-height: 100vh;
  padding: 40px;
  background: var(--primary-blue);
  font-family: Inter, sans-serif;

  @media screen and (max-width: 768px) {
    padding: 20px;
    overflow: hidden;
  }

  @media screen and (max-width: 360px) {
    padding: 20px 10px;
  }


  .container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    .header {
      margin-bottom: 28px;

      @media screen and (max-width: 768px) {
        margin-bottom: 15px;
      }
    }
  }
}
