.container {
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    align-items: center;
    column-gap: 30px;
    height: 40px;

    .title {
      padding: 0 7px;
    }

    .dashes {
      width: 100%;
      border-bottom: 1px dashed var(--primary-blue-100);
    }
  }

  .loader {
    width: 0;
    height: 3px;
    background: var(--primary-yellow);
    animation: loadAnimation 2s 0.5s forwards;
  }
}

@keyframes loadAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
