.rotate-arrow {
    transform: rotate(180deg);
}

.normal-arrow {
    transform: none;
}

.game-product-image {
    width: 70px;
    height: 55px;
}

.game-active-product {
    border: 1px green solid;
}

.horizontal-menu {
    overflow: hidden;
}

.menu-wrapper {
    margin-left: 15px;
    margin-right: 15px;
}

.game-button-height {
    height: 40px;
}

.card-columns {
    column-count: 2;
}
