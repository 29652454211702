.text {
  // Sizes
  &.small {
    font-size: 12px;
  }

  &.medium {
    font-size: 16px;
  }

  &.large {
    font-size: 43px;
  }

  // Colors
  &.white {
    color: var(--basic-white);
  }

  &.yellow {
    color: var(--primary-yellow);
  }

  // Weights
  &.regular {
    font-weight: 400;
  }

  &.mediumbold {
    font-weight: 500;
  }

  &.semibold {
    font-weight: 600;
  }

  &.bold {
    font-weight: 700;
  }

  &.bolder {
    font-weight: 800;
  }

  &.extrabold {
    font-weight: 900;
  }
}
