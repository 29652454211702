.audio {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 70px;
   height: 70px;
   background: #000;
   border-radius: 50%;
   border: 5px solid black;
   cursor: pointer;

   &.unlocked {
      cursor: pointer;
   }

   svg {
      width: 70px;
      height: 70px;
   }


   @media screen and (max-width: 540px) {
      width: 65px;
      height: 65px;
   }
}