.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;

  .tickerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 240px;
    height: 100%;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 45px;

    @media screen and (max-width: 768px) {
      height: 30px;
    }

    .info {
      display: flex;
      align-items: center;
      height: 45px;
  
      @media screen and (max-width: 768px) {
        height: 30px;
      }
  
      .blank {
        margin-right: 60px;
  
        @media screen and (max-width: 768px) {
          height: 100%;
          min-height: unset;
          margin-right: 0;
          padding: 0 10px;
        }
        
        .title {
          @media screen and (max-width: 390px) {
            font-size: 12px;
            font-weight: 700;
          }
        }
      }
  
      .logo {
        width: auto;
        height: 100%;
        border-right: 3px solid var(--primary-yellow);
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);
      }

      .tickerWrapper {
        padding: 0 0 0 20px;
      }
    }
  
    .go {
      min-height: unset;
      height: 100%;
    }
  }
}