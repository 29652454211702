.blank {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 15px;
  min-height: 45px;
  width: fit-content;
  background: var(--primary-white-transparent);

  &.left {
    border-left: 3px solid var(--primary-yellow);
  }

  &.right {
    border-right: 3px solid var(--primary-yellow);
  }

  &.hideLine {
    border: none;
  }
}
