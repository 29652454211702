.token-image {
    height: 67px;
    width: 67px;
}
.red-circle {
    width: 15px;
    height: 15px;
    background: var(--red);
    border-radius: 50%;
}
.blue-circle {
    width: 15px;
    height: 15px;
    background: var(--blue);
    border-radius: 50%;
}
.red-span {
    width: 88px;
    height: 19px;
    background: var(--red);
}
.game-data-details {
    position: absolute;
    top: 26px;
    width: 250px;
    background: var(--black);
}
.game-data {
    width: 250px;
}
.game-data-details p {
    color: var(--white)
}
.selected-circle {
    border: 1px solid;
    padding: 5px;
}