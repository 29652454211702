.menuWrapper {
  position: relative;
  width: 100%;
  padding: 16px;

  .button {
    background: none;
    border: none;
    outline: none;
    position: absolute;
    top: 0;
    left: calc(50% - 40px);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      max-height: 10px;
      margin-left: 4px;
    }
  }
}
