.button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 45px;
  padding: 0 20px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  font-weight: 900;

  &.yellow {
    background: var(--primary-yellow);
    color: var(--primary-blue-500);
  }
}
