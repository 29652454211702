.image {
  opacity: 0.5;
  cursor: pointer;
  margin: 4px;

  &:hover,
  &.active {
    opacity: 1;
  }

  &.active {
    border: 2px solid var(--orange);
  }
}
