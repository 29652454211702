.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .p {
        margin: 0;
        color: #4a4a4a;
        text-align: center;
    }

    .button {
        color: var(--yellow);
        background-color: var(--primary-blue);
    }

    .button:hover {
        background-color: var(--    blue);
    }
}
